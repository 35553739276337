Vue.component('cookiescript-declaration', {
    template: `
        <section class="cookiescript-declaration-block block block--none">
        <div id="CookieDeclaration"/></section>`,
    props:{
        domainId: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            // Load external js
            const script = document.createElement('script');
            script.src = '//report.cookie-script.com/r/' + this.domainId + '.js';
            script.setAttribute('data-cookiescriptreport', 'report')
            document.getElementById('CookieDeclaration').appendChild(script);
            this.$root.cookiebotDeclarationLoaded = true
        }
    }
});
